
import { defineComponent, ref } from "vue";

export default defineComponent({
  name: "Loading",
  props: {
    loadingText: {
      type: String,
    },
  },
  setup() {
    const animate = ref(false);

    setTimeout(() => {
      animate.value = true;
    }, 50);

    return {
      animate,
    };
  },
});
